
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { ManageUsersSetup } from './ManagePermissionsSetup';
import { ManageUsersModalSetup } from './ManagePermissionsModalSetup';
import { ManageUsersAsideSetup } from './ManagePermissionsAsideSetup';
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import AgGridVerifiedBadge from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridVerifiedBadge.vue";
import AgGridEditButton from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridEditButton.vue";
// import MessageBox from "@/components/notifications/BaseMessageBox.vue";
import KTNewPermissionModal from "@/components/modals/forms/NewFormModal.vue";
import KTEditPermissionAside from "@/layout/aside/EditAside.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";


export default defineComponent({
  name: 'ManageUsers',
  components: {
    AgGridFP,
    // MessageBox,
    KTNewPermissionModal,
    KTEditPermissionAside,
    ErrorMessage,
    Field,
    Multiselect
  },
  setup() {
    const frameworkComponents = reactive({
      AgGridVerifiedBadge,
      AgGridEditButton,
    });


    const {
      usersArrayData,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      setGridApi,
      setColApi,
      rowValueChanged,
      cellValueChanged,
      deleteItem,
      applyState,
      handleSaveState,
      testData,
      selectedUser,
    } = ManageUsersSetup();

    const {

      saveEdit,
      userRoles,
      asideValidationSchema,
      handleClose,
      multiSelectMsg,
    } = ManageUsersAsideSetup(selectedUser);

    const {
      newUserData,
      modalValidationSchema,
      onSubmitUser,
      submitButtonRef,
      loading,
      handleReset
    } = ManageUsersModalSetup();

    return {
      selectedUser,
      usersArrayData,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      setGridApi,
      setColApi,
      rowValueChanged,
      cellValueChanged,
      deleteItem,
      applyState,
      handleSaveState,
      testData,
      frameworkComponents,
      newUserData,
      modalValidationSchema,
      onSubmitUser,
      submitButtonRef,
      loading,

      saveEdit,
      userRoles,
      asideValidationSchema,
      handleClose,
      multiSelectMsg,
      handleReset

    }
  }
})
